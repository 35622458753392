<template>
    <div>
        <div class="stuContact A4Container flexCloumnCenter pageBreak_no">
            <div class="title">实 习 周 记</div>
            <div class="" style="font-size: 15px;">（{{ info.shiduan }}）</div>
            <div class="form">
                <div class="flexCloumnSingleCenter" style="width: 100%;height: 100%;">
                    <div style="width: 100%;height: 100%;">
                        <div class="content el-row"
                            style="width:100%;height: 75%;overflow: hidden;box-sizing: border-box;position: relative;">
                            <div :id="'divCntnrA_' + idx" :style="(info.att_coutent && info.att_coutent.length > 0) ?
                                'width:100%;height: calc( 100% - 160px );overflow: hidden;' :
                                'width:100%;height: 100%;overflow: hidden;'">
                                <div :id="'resizeDivA_' + idx" style="width: 100%;word-break: break-all">
                                    <div v-for="(items, indexs) in info.report_info">
                                        <div v-if="items.type != 'file'"
                                            :class="[(items.type == 'checkbox' || items.type == 'radio' || items.type == 'select') ? 'flexStart' : 'flexCloumn']">
                                            <div class="content_title" style="font-weight: 700;width: unset;">
                                                {{ items.name }}：</div>
                                            <div class="content_real_values flex_1"
                                                style="width: unset;white-space: normal;">{{ items.real_values || '-' }}
                                            </div>
                                        </div>
                                        <div v-if="items.type == 'file' && items.real_values && items.real_values.length > 0"
                                            class="" style="width: 100%;min-height: 150px;overflow: hidden;">
                                            <div class="content_title" style="font-weight: 700;width: unset;height: 30px;">
                                                {{ items.name }}：</div>
                                            <div class="flexStart" style="width:100%;flex-wrap:wrap;">
                                                <div v-for="(itemss, indexss) in items.real_values" :key="indexss"
                                                    style="width: 19%;height: calc( 100% - 30px );margin-right: 1%;">
                                                    <img :src="itemss.fileUrl" alt=""
                                                        style="max-width: 100%;max-height: 100%;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="commit el-row" style="position: relative;width: 100%;height: 25%;padding: 10px;">
                            <div style="width: 100%;height:100%;word-break: break-all">
                                <div :id="'divCntnrB_' + idx"
                                    style="width: 100%;height: calc( 100% - 70px );overflow: hidden">
                                    <div :id="'resizeDivB_' + idx" style="width: 100%;word-break: break-all">
                                        评语：{{ info.review_comment }}
                                    </div>
                                </div>
                                <div style="position: absolute;bottom: 10px;right: 10px;line-height: unset!important;">
                                    <div class="flexStart" style="line-height: unset!important;">{{ info.review_time }}
                                    </div>
                                    <div class="flexStart" style="line-height: unset!important;">
                                        <div>班主任(带队教师) 签字：</div>
                                        <img v-if="info.sign_img" :src="info.sign_img" alt=""
                                            style="max-width: 100px;max-height: 50px;">
                                        <div v-else style="width: 100px;"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'zhoubao',
    data() {
        return {
            dataover: false,
            userid: '',
            info: {}
        }
    },
    props: {
        info1: {
            type: Object,
            default: {}
        },
        idx: {
            type: Number,
            default: 1
        },
        stuinfo: {
            type: Object,
            default: {}
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            let _this = this
            let info = JSON.parse(JSON.stringify(_this.info1))

            if (info.report_year && info.number) {
                let s = this.$moment(`${info.report_year}-${info.number}`, 'GGGG-WW').format('YYYY年MM月DD日')
                let e = this.$moment(`${info.report_year}-${info.number}`, 'GGGG-WW').weekday(7).format('YYYY年MM月DD日')
                info.shiduan = `${s} 至 ${e}`
            }

            if (info.report_info) {
                if (this.isJSON(info.report_info)) {
                    info.report_info = JSON.parse(info.report_info)
                    console.log(145145145145145, info.report_info)
                    info.report_info.map(a => {
                        if (a.type == 'file' && a.real_values && a.real_values.length > 0) {
                            console.log(145145145145145, a)
                            a.real_values.map(f => {
                                f.fileUrl += `?x-oss-process=image/resize,m_fill,h_150,w_110`
                                console.log(f.fileUrl)
                            })
                        }
                    })
                }

                // info.report_info = _this.stripscript(info.report_info)
                _this.info = { ...info }
                _this.$nextTick(() => {
                    _this.resizeA()
                    _this.resizeB()
                })


            } else {
                setTimeout(function () {
                    _this.init()
                }, 500)
            }
        },


        stripscript(s) {
            var pattern = new RegExp('\n')
            var rs = ''
            for (var i = 0; i < s.length; i++) {
                rs = rs + s.substr(i, 1).replace(pattern, '')
            }
            return rs
        },
        resizeA() {
            let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
            let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
            let i = 1
            while (textHeight > (divHeight - 100) && i < 5) {
                document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
                textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
                i++
            }
        },
        resizeB() {
            let divHeight = document.getElementById(`divCntnrB_${this.idx}`).offsetHeight
            let textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
            let i = 1
            while ((textHeight > divHeight) && i < 5) {
                document.getElementById(`resizeDivB_${this.idx}`).style.fontSize = `${14 - i}px`
                textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
                i++
            }
        }
    }
}
</script>

<style lang="less" type="text/less">
.stuContact .ant-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.stuContact .ant-row {
    width: 100%;
    padding: 0;
    margin: 0;
}
</style>
<style lang="less" type="text/less" scoped>
.stuContact {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 25px 0;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form {
        width: calc(100% - 50px);
        height: calc(100% - 70px);
        border: 1px solid #000;
        border-top: 0;
        overflow: hidden;

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .content {
            width: 100%;
            height: 100%;
            padding: 10px;
            white-space: break-spaces;
            word-break: break-all;
            overflow: hidden;
        }

        .el-row {
            border-top: 1px solid #000;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
</style>
