<template>
    <div>
        <div class="perInfo A4Container flexCloumnCenter pageBreak_no">
            <div class="title flexCloumnSingleCenter" style="width: 100%;">
                <div class="FS18 bold" style="line-height: 50px;">实 习 生 登 记 表</div>
                <div class="flexEnd" style="width: 100%;margin-right: 50px;font-size: 15px;"> 填表日期: {{
                    stuinfo.createDate }}
                </div>
            </div>
            <div class="form">
                <div class="table" style="height: 100%;">
                    <el-row class="flexStretch">
                        <el-col :span="19">
                            <div style="width: 100%;">
                                <el-row>
                                    <el-col :span="6" class="cell cell50 flexCenter" style="border-left: 0;">姓名</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter">{{ stuinfo.stuName }}</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter">性别</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter">
                                        {{ stuinfo.sexName || '-' }}
                                    </el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="6" class="cell cell50 flexCenter"
                                        style="border-left: 0;">出生年月</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter">{{ stuinfo.birthday }}</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter">民 族</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter">{{ stuinfo.nationName }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="6" class="cell cell50 flexCenter"
                                        style="border-left: 0;">政治面貌</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter">{{ stuinfo.politicalStatusName
                                        }}</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter">户籍地</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter"
                                        style="word-break: break-all;white-space:normal;line-height: unset!important;padding: 0 2px;">{{
                                        pmid == 215 ? stuinfo.hkszd :stuinfo.jiguan }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="6" class="cell cell50 flexCenter"
                                        style="border-left: 0;">家庭住址</el-col>
                                    <el-col :span="18" class="cell cell50" style="padding: 0 3px;">{{ stuinfo.address }}
                                    </el-col>

                                </el-row>
                            </div>


                        </el-col>
                        <el-col :span="5" class="cellMore flexCenter">
                            <div class="flexCenter" v-if="stuinfo.cover"
                                style="width: 100%;height: 200px;box-sizing:border-box;padding:1px;overflow: hidden">
                                <img v-if="stuinfo.cover" :src="stuinfo.cover" alt="" style="width: 100%;">
                            </div>
                            <span v-else>贴照<br />片处</span>
                        </el-col>
                    </el-row>

                    <el-row class="flexStretch">
                        <el-col :span="19">
                            <el-row>
                                <el-col :span="6" class="cell flexCenter" style="border-left: 0;height: 100%;">
                                    联 系<br />
                                    电 话
                                </el-col>

                                <el-col :span="18">
                                    <div style="width: 100%;">
                                        <el-row>
                                            <el-col :span="8" class="cell cell50 flexCenter">手 机</el-col>
                                            <el-col :span="8" class="cell cell50 flexCenter">{{ stuinfo.phone || '-'
                                                }}</el-col>
                                            <el-col :span="8" class="cell cell50 flexCenter">宅 电</el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="8" class="cell cell50 flexCenter">单位电话</el-col>
                                            <el-col :span="8" class="cell cell50 flexCenter">{{ stuinfo.tel || '-'
                                                }}</el-col>
                                            <el-col :span="8" class="cell cell50 flexCenter">班主任电话</el-col>
                                        </el-row>
                                    </div>

                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="5">
                            <div style="width: 100%;">
                                <el-row>
                                    <el-col :span="24" class="cell cell50 flexCenter">{{ stuinfo.home_tel || '-'
                                        }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24" class="cell cell50 flexCenter">{{ stuinfo.bzr_phone || '-'
                                        }}</el-col>
                                </el-row>
                            </div>

                        </el-col>




                    </el-row>

                    <div style="height: calc( 100% - 300px )">
                        <el-row class="" style="border-left: 0;height: 57%;">
                            <el-col :span="2" class="cell flexCenter" style="border-left: 0;height: 100%;">
                                专<br />业<br />技<br />能<br />和<br />证<br />书</el-col>
                            <el-col :span="22" class="cell flexCloumn" style="height: 100%;padding: 10px;">

                                <div v-if="stuinfo.skill_json && stuinfo.skill_json.length > 0" class=""
                                    v-for="(item, index) in stuinfo.skill_json">
                                    <div class="">{{ index+1 }}.{{ item }}</div>
                                </div>
                                <div v-if="stuinfo.skill_img && stuinfo.skill_img.length > 0" class=""
                                    v-for="(item, index) in stuinfo.skill_img">
                                    <div class="">{{ index + 1 }}.{{ item.fileName||item.name }}</div>
                                </div>
                                <div v-else class="">-</div>
                            </el-col>
                        </el-row>

                        <el-row class="" style="border-left: 0;height: calc( 43% - 5px );">
                            <el-col :span="2" class="cell flexCenter" style="border-left: 0;height: 100%;">
                                奖<br />惩<br />情<br />况<br />
                            </el-col>
                            <el-col :span="22" class="cell" style="height: 100%;padding: 10px;">{{ stuinfo.awards
                                || '-' }}</el-col>
                        </el-row>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    props: {
        stuinfo: {
            type: Object,
            default: {}
        },
        pmid: {
            type: Number,
            default:0,
        },
    },
    data() {
        return {
            isShowModal: true,
        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="less" type="text/less">
.perInfo .el-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.perInfo .el-row {
    width: 100%;
    padding: 0;
    margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
.flexStretch {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

.perInfo {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 25px 0;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border: 1px solid #000;
        border-top: 0;

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .content {
            width: 100%;
            height: 100%;
            padding: 10px;
            white-space: break-spaces;
            word-break: break-all;
            overflow: hidden;
        }

        .el-row {
            //border-top: 1px solid #000;
            //border-bottom: 1px solid #000;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}


.perInfo {

    .table {
        width: 100%;
    }

    .borderR {
        border-right: 1px solid #333;
    }

    .borderB {
        border-bottom: 1px solid #333;
    }

    .FS18 {
        font-size: 18px;
    }

    .bold {
        font-weight: 700;
        font-family: Avenir, Helvetica, Arial, sans-serif;
    }

    .cell {
        border-left: 1px solid #333;
        border-top: 1px solid #333;

        min-height: 30px;
        //line-height: 30px;
        //text-align: center;
        white-space: nowrap;
        font-size: 14px;
        font-family: "宋体";
        word-break: break-all;
        white-space: normal;
    }

    .cell50 {
        min-height: 50px;
        line-height: 50px;
        font-size: 14px;
        font-family: "宋体";
    }

    .cellMore {
        border-left: 1px solid #333;
        border-top: 1px solid #333;
        min-height: 50px;
        text-align: left;
        font-size: 14px;
        font-family: "宋体";
    }
}

.uline {
    text-decoration: underline;
}</style>
