<template>
    <div>
        <div class="perInfo A4Container flexCloumnCenter pageBreak_no">
            <div class="title flexCloumnSingleCenter" style="width: 100%;">
                <div class="FS18 bold" style="line-height: 50px;">实 习 指 导 教 师 鉴 定 意 见 表</div>
            </div>
            <div class="form">
                <div class="table" style="height: 100%;">
                    <el-row class="flexStretch">
                        <el-col :span="24">
                            <div style="width: 100%;">
                                <el-row>
                                    <el-col :span="3" class="cell cell50 flexCenter" style="border-left: 0;">姓名</el-col>
                                    <el-col :span="4" class="cell cell50 flexCenter">{{ stuinfo.stuName }}</el-col>
                                    <el-col :span="3" class="cell cell50 flexCenter">专业</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter">
                                        {{ stuinfo.major_name || '-' }}
                                    </el-col>
                                    <el-col :span="3" class="cell cell50 flexCenter">班级</el-col>
                                    <el-col :span="5" class="cell cell50 flexCenter">{{ stuinfo.class_name ||
                                        '-' }}</el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="3" class="cell cell50 flexCenter"
                                        style="border-left: 0;">实习单位</el-col>
                                    <el-col :span="21" class="cell cell50" style="padding: 0 10px;">{{ stuinfo.firm_name
                                        || "-" }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="3" class="cell cell50 flexCenter"
                                        style="border-left: 0;">实习岗位</el-col>
                                    <el-col :span="5" class="cell cell50 flexCenter">{{ stuinfo.job || "-" }}</el-col>
                                    <el-col :span="3" class="cell cell50 flexCenter">实习指导教师</el-col>
                                    <el-col :span="4" class="cell cell50 flexCenter"
                                        style="word-break: break-all;white-space:normal;line-height: unset!important;padding: 0 2px;">{{
                                        stuinfo.firm_principal || '-' }}</el-col>
                                    <el-col :span="3" class="cell cell50 flexCenter">电话</el-col>
                                    <el-col :span="6" class="cell cell50 flexCenter">{{ stuinfo.tel || "-" }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="6" class="cell cell50 flexCenter"
                                        style="border-left: 0;">实习时间</el-col>
                                    <el-col :span="18" class="cell cell50 flexCenter">
                                        {{ stuinfo.startTime | dateFormat }} - {{ stuinfo.endTime | dateFormat }}
                                    </el-col>

                                </el-row>
                                <el-row>
                                    <el-col :span="6" class="cell cell50 flexCenter"
                                        style="border-left: 0;">实习成绩（百分制）</el-col>
                                    <el-col :span="18" class="cell cell50 flexCenter">
                                    </el-col>

                                </el-row>
                                <el-row>
                                    <el-col :span="6" class="cell cell50 flexCenter"
                                        style="border-left: 0;line-height: unset!important;padding:0 3px;">
                                        总评成绩 (总评成绩为企业考评成绩与实习指导教师考评成绩平均值)
                                    </el-col>
                                    <el-col :span="18" class="cell cell50 flexCenter">
                                    </el-col>

                                </el-row>
                            </div>
                        </el-col>
                    </el-row>

                    <div style="height: calc( 100% - 305px )">
                        <el-row class="" style="border-left: 0;height: 100%;">
                            <el-col :span="2" class="cell flexCenter" style="border-left: 0;height: 100%;">
                                实<br />习<br />指<br />导<br />教<br />师<br />评<br />语<br /></el-col>
                            <el-col :span="22" class="cell flexCloumn" style="height: 100%;padding: 10px;">
                                <div style="position: absolute;bottom: 10px;right: 0;">
                                    <div style="margin-bottom: 30px;margin-right: 40px;">实习指导教师签名：</div>
                                    <div style="letter-spacing: 50px;">年月日</div>
                                </div>

                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    props: {
        stuinfo: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            isShowModal: true,
        }
    },
    mounted() {

    },
    filters: {
        dateFormat(date) {
            if (date) {
                let arr = date.split('-')
                return `${arr[0]} 年 ${arr[1]} 月 ${arr[2]} 日`
            }
            return ""
        },
    },
    methods: {

    }
}
</script>

<style lang="less" type="text/less" scoped="scoped">
.perInfo .el-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.perInfo .el-row {
    width: 100%;
    padding: 0;
    margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
.flexStretch {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

.perInfo {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 25px 0;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
        border: 1px solid #000;
        border-top: 0;

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .content {
            width: 100%;
            height: 100%;
            padding: 10px;
            white-space: break-spaces;
            word-break: break-all;
            overflow: hidden;
        }

        .el-row {
            //border-top: 1px solid #000;
            //border-bottom: 1px solid #000;
        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}


.perInfo {

    .table {
        width: 100%;
    }

    .borderR {
        border-right: 1px solid #333;
    }

    .borderB {
        border-bottom: 1px solid #333;
    }

    .FS18 {
        font-size: 18px;
    }

    .bold {
        font-weight: 700;
        font-family: Avenir, Helvetica, Arial, sans-serif;
    }

    .cell {
        border-left: 1px solid #333;
        border-top: 1px solid #333;

        min-height: 30px;
        //line-height: 30px;
        //text-align: center;
        white-space: nowrap;
        font-size: 14px;
        font-family: "宋体";
        word-break: break-all;
        white-space: normal;
    }

    .cell50 {
        min-height: 50px;
        line-height: 50px;
        font-size: 14px;
        font-family: "宋体";
    }

    .cellMore {
        border-left: 1px solid #333;
        border-top: 1px solid #333;
        min-height: 50px;
        text-align: left;
        font-size: 14px;
        font-family: "宋体";
    }

    .grade-result {
        color: #333;
        width: 13px;
        height: 13px;
        margin-right: 40px;
        appearance: none;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            width: 100%;
            height: 100%;
            border: 1px solid #333;
            border-radius: 4px;
        }

        &:checked::after {
            content: "\2713";
            background-color: #fff;
            color: #333;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #333;
            border-radius: 4px;
            font-size: 13px;
            font-weight: bold;
            text-align: center;
            line-height: 13px;
        }
    }
}

.uline {
    text-decoration: underline;
}
</style>
